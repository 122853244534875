// Components
import VuetifyTabs from '@/components/ui/VuetifyTabs'
import RationForm from '../RationForm'
import TranslateForm from '@/components/elements/translate/TranslateForm'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'RationFormByTabs',
  components: {
    VuetifyTabs
  },
  props: {
    // Uid from DB
    id: {
      type: String,
      default: null
    },
    // Pestañas "adicionales" de configuración
    tabs: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * Establecemos/obtenemos todos los datos
     * necesarios para inicializar el componente
     */
    getEveryNeededData() {
      // Pestañas disponibles
      const basicTab = this.tabs.find((tab) => tab.id === 'basic')
      const translationTab = this.tabs.find((tab) => tab.id === 'translation')

      // Pestaña datos básicos
      if (!isNil(basicTab)) {
        this.items.push({
          label: 'Básico',
          component: RationForm,
          componentProps: {
            id: this.id,
            ...get(basicTab, 'options', {})
          }
        })
      }

      // Pestaña multi-idiomas
      if (!isNil(translationTab)) {
        this.items.push({
          label: 'Traducciones',
          component: TranslateForm,
          componentProps: { id: this.id, model: 'metaField', ...get(translationTab, 'options', {}) }
        })
      }
    }
  }
}

// Components
import draggable from 'vuedraggable/src/vuedraggable'

export default {
  name: 'DraggableList',
  components: { draggable },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    handle: {
      type: String,
      default: '.handle'
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    tag: {
      type: String,
      default: 'ul'
    },
    // Como se muestran los elementos
    column: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    /**
     * Handle when the items are reordered
     */
    handleChange() {
      this.$emit('onChange', this.items)
    }
  }
}

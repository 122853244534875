// Constantes
import { ADDONS } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Services
import {
  updateTranslationsByModel,
  getFieldsToTranslateByModel,
  getTranslationsAttributeByModel
} from '@/services/translate'
// Vuex
import { mapGetters, mapState } from 'vuex'
// Utils
import { getPathImage } from '@/utils'
import { get, isNil, set } from 'lodash'
// Filters
import { sanitizeHtmlContent } from '@/filters'

export default {
  name: 'TranslateForm',
  components: { FormButtons, VuetifyContentLoading },
  mixins: [addonsMixin, formMixin, uiMixin],
  props: {
    // modelo a traducir
    model: {
      required: true,
      type: String,
      default: null
    },
    // Variables del componente padre "VuetifyTabs"
    index: {
      // Indice que ocupo dentro del componente
      type: Number,
      default: 0
    },
    itemsData: {
      // Datos que se comparten entre pestaÃ±as del componente
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Model
      modelData: null,
      // Form
      formFields: {},
      // Others
      availableLanguages: null, // Todos los idiomas disponibles
      fieldsToTranslate: null, // Campos del modelo a traducir
      processingRequest: true,
      selectedLanguage: null, // Idioma seleccionado
      translationsAttribute: null // Atributo del modelo donde se almacena las traducciones
    }
  },
  computed: {
    ...mapGetters('place', ['placeData', 'additionalLanguages']),
    ...mapState('config', ['languages']),
    /**
     * Campos que son editables en el formulario
     *
     * @return {array | boolean} - listado de los campos editables
     */
    editableFields() {
      const owner = get(this.modelData, 'owner', null)
      const editabled = get(this.modelData, 'editabled', false)
      const fieldsCustomItem = get(this.modelData, 'fieldsCustomItem', [])

      if (!isNil(owner)) {
        return editabled ? fieldsCustomItem : []
      }

      return true // todo es editable
    },
    /**
     * El formulario es editable?
     *
     * @return {boolean}
     */
    formIsEditable() {
      return (
        (Array.isArray(this.editableFields) && this.editableFields.length > 0) ||
        this.editableFields === true
      )
    }
  },
  watch: {
    itemsData: {
      async handler(value) {
        try {
          // show loading
          this.processingRequest = true
          // Datos de la primera pestaÃ±a (vuetifyTabs)
          this.modelData = get(value, '0.data', null)
          // Idiomas disponibles
          this.availableLanguages = this.languages.filter((lang) => {
            return this.additionalLanguages.indexOf(lang.id) > -1
          })
          // Idioma seleccionado
          this.selectedLanguage = get(this.availableLanguages, '0.id', null)
          // Campos a traducir
          this.fieldsToTranslate = await getFieldsToTranslateByModel(this.model)
          // Esto lo hacemos porque las categorías no deben tener el campo de traducción de la descripción
          const parentId = get(this.modelData, 'parentId', null)
          if (this.model === 'category' && !isNil(parentId)) {
            this.fieldsToTranslate = this.fieldsToTranslate.filter((item) => {
              return item.id !== 'description'
            })
          }
          // Atributo donde se almacerá la traducción
          this.translationsAttribute = await getTranslationsAttributeByModel(this.model)
          // generamos los campos del formulario
          await this.setFormFieldsValues()
        } catch (error) {
          // Show error
          this.modifyAppAlert({
            text: error.message,
            type: 'error',
            visible: true
          })
        } finally {
          // hide loading
          this.processingRequest = false
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * Dado un campo del formulario, comprobamos si este puede
     * ser editado
     *
     * @param {string} name - nombre del campo
     * @return {boolean} - es editable?
     */
    isEditable(name = null) {
      return Array.isArray(this.editableFields)
        ? this.editableFields.indexOf(name) > -1
        : this.editableFields
    },
    /**
     * Establecemos los campos del formulario y sus valores
     *
     * @return {Object} - form fields generated
     */
    async setFormFieldsValues() {
      // Generamos los distintos campos a traducir.
      // Si el campo originario estÃ¡ vacÃ­o, el campo
      // relacionado, a traducir, no se mostrarÃ¡
      if (
        Array.isArray(this.availableLanguages) &&
        this.availableLanguages.length > 0 &&
        Array.isArray(this.fieldsToTranslate) &&
        this.fieldsToTranslate.length > 0
      ) {
        // Repasamos cada idioma
        this.formFields = this.availableLanguages.reduce((accLangs, lang) => {
          // Repasamos cada uno de los campos
          const everyFields = this.fieldsToTranslate.reduce((accFields, field) => {
            accFields[field.id] = {
              id: field.id,
              label: field.label,
              type: field.type,
              value: get(
                this.modelData,
                `${this.translationsAttribute}.${lang.id}.${field.id}`,
                null
              )
            }
            return accFields
          }, {})

          // Incluimos campos por cada una de las pestaÃ±as de idiomas
          set(accLangs, lang.id, everyFields)

          return accLangs
        }, {})
      }
    },
    /**
     * Get path image
     *
     * @return {string} - image url
     */
    getFlagImage(path, brand = false) {
      return getPathImage(path, brand)
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Configuraciones del establecimiento
      const placeConfig = get(this.placeAddonsSetupByUser, ADDONS.place, {})
      // Parseamos los datos antes de salvar
      const parsedFormFields = Object.entries(this.formFields).reduce((accLangs, lang) => {
        const langId = lang[0]
        const langValues = Object.entries(lang[1])
        const langFields = langValues.reduce((accFields, field) => {
          accFields[field[1].id] = sanitizeHtmlContent(field[1].value, []).replace(/&amp;/g, '&') // SoluciÃ³n adHoc para el problema con los "&"
          return accFields
        }, {})

        accLangs[langId] = langFields

        return accLangs
      }, {})

      // Almacenamos datos en el modelo indicado
      await updateTranslationsByModel(
        this.model,
        {
          id: this.modelData.id,
          [this.translationsAttribute]: parsedFormFields
        },
        {
          additionalLanguages: placeConfig.additionalLanguages,
          defaultLanguage: placeConfig.defaultLanguage
        }
      )

      // InformaciÃ³n resultados acciÃ³n de salvado
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    }
  }
}

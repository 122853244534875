// Constants
import { ADDONS, DEFAULT_LANGUAGE } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import DraggableList from '@/components/ui/DraggableList'
import RationFormByTabs from '@/components/elements/rations/RationFormByTabs'
import RationsListItem from '@/components/elements/rations/RationsListItem'
import SectionInformationBox from '@/components/ui/SectionInformationBox'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters, mapActions, mapState } from 'vuex'
// Services
import { updateRationById, getEveryRationsByPlaceId } from '@/services/ration'
// Utils
import { getEnvironmentVariable, getPathImage } from '@/utils'
import { get, isNil } from 'lodash'

export default {
  name: 'RationsList',
  components: {
    CardContainer,
    DraggableList,
    RationsListItem,
    SectionInformationBox,
    VuetifyContentLoading,
    VuetifyToolBar
  },
  mixins: [addonsMixin, uiMixin],
  data() {
    return {
      items: [],
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData', 'areThereAdditionalLanguages']),
    ...mapState('app', ['extraSmallDevice']),
    /**
     * Get the options to show the screen when there are not items
     *
     * @return {Object} - objeto opciones para mostrar
     */
    sectionInformationOptions() {
      return {
        media: getPathImage('onboarding/categories.svg'),
        title: 'Tipos de raciones',
        description: 'Aún no has creado ningún tipo de ración',
        buttonLabel: 'Crear tipo de ración ¡ahora!'
      }
    },
    /**
     * La "toolBar" es sticky
     *
     * @return {Boolean}
     */
    toolBarIsSticky() {
      return this.extraSmallDevice
    }
  },
  async mounted() {
    // Capturamos evento
    this.$root.$on('reload-list', this.getEveryNeededData)
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  destroyed() {
    // Paramos escucha
    this.$root.$off('reload-list')
  },
  methods: {
    ...mapActions('meta', ['getEveryRations']),
    /**
     * handle change order in items
     *
     * @param {Array} items - items ordered
     */
    async handleChangeOrder(items) {
      await Promise.all(
        items.map(async (item, index) => {
          await updateRationById({ id: item.id, order: index })
        })
      )
      // Refresco las raciones en el Store
      this.getEveryRations({
        placeId: this.placeData.id,
        brand: getEnvironmentVariable('VUE_APP_BRAND')
      })
    },
    /**
     * Abre un "dialog" para crear/editar una categoría
     *
     * @param {string} id - UID category en base de datos
     */
    handleRationFormDialog(id = null) {
      // Configuraciones del establecimiento
      const placeConfig = get(this.placeAddonsSetupByUser, ADDONS.place, {})
      // Pestañas adicionales de configuración a mostrar en el formulario
      const tabs = [
        {
          id: 'basic',
          options: {
            additionalLanguages: get(placeConfig, 'additionalLanguages', []),
            currency: get(placeConfig, 'currency', null),
            defaultLanguage: get(placeConfig, 'defaultLanguage', DEFAULT_LANGUAGE)
          }
        }
      ]

      // Pestaña de traducciones
      if (this.areThereAdditionalLanguages) {
        tabs.push({
          id: 'translation',
          options: {
            additionalLanguages: get(placeConfig, 'additionalLanguages', []),
            defaultLanguage: get(placeConfig, 'defaultLanguage', DEFAULT_LANGUAGE)
          }
        })
      }

      this.modifyAppDialog({
        title: isNil(id) ? 'Crear nueva ración' : 'Editar ración',
        contentComponent: RationFormByTabs,
        contentComponentProps: {
          id,
          model: 'company',
          modelId: this.companyData.id,
          tabs
        },
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Obtenemos todos los datos necesarios para
     * inicializar el listado de categorías
     */
    async getEveryNeededData() {
      try {
        // Loading
        this.processingRequest = true
        // Raciones del (solo) del establecimiento
        this.items = await getEveryRationsByPlaceId(this.placeData.id)
      } catch (error) {
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    }
  }
}

// Constantes
import { ADDONS } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import { deleteDishPricesByRationIdsAndPlaceId } from '@/services/dish'
import {
  createRationInPlace,
  getRationById,
  updateRationById,
  deleteRationById
} from '@/services/ration'
// Custom validations
import { getEnvironmentVariable, isDecimalNumber, stringToNumber } from '@/utils'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'RationForm',
  components: {
    FormButtons,
    VuetifyContentLoading
  },
  mixins: [addonsMixin, formMixin, uiMixin, validationMixin],
  props: {
    id: {
      type: String,
      default: null
    },
    // Variables del componente padre "VuetifyTabs"
    index: {
      // Indice que ocupo dentro del componente
      type: Number,
      default: 0
    },
    itemsData: {
      // Datos que se comparten entre pestañas del componente
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Form
      formFields: {
        name: null,
        price: null
      },
      formFieldsValidations: {
        name: {
          required: 'Campo obligatorio'
        },
        price: {
          numberFormat: 'Formato incorrecto'
        }
      },
      // Others
      currentId: this.id,
      rationData: null,
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData', 'areThereAdditionalLanguages', 'currencyData']),
    /**
     * Currency Symbol
     *
     * @return {String}
     */
    currencySymbol() {
      return get(this.currencyData, 'symbol', null)
    },
    /**
     * Get the labels to show in the formulary
     *
     * @return {Object} - diffent labels
     */
    formLabels() {
      const labels = {
        labelButtonSave:
          Array.isArray(this.additionalLanguages) &&
          this.additionalLanguages.length > 0 &&
          isNil(this.currentId)
            ? 'Siguiente'
            : 'Guardar',
        labelButtonCancel: 'Cerrar'
      }

      return labels
    }
  },
  async mounted() {
    // Iniciamos/obtenemos los componentes iniciales
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('meta', ['getEveryRations']),
    /**
     * Modificamos el atributo "itemsData" del componente padre
     *
     * @param {Object} data - datos a emitir
     */
    changeItemsData(data) {
      this.$parent.$parent.$parent.$emit('onChangeItemsData', this.index, data)
    },
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Esta función es llamada desde el padre (VuetifyTabs)
     * cuando el usuario pulsa sobre alguno de las pestañas
     *
     * @param {Number} tab - pestaña donde deseamos movernos
     */
    async fnToChangeTab(tab) {
      let result = false

      try {
        if (isNil(this.currentId)) {
          // Create categorie
          result = await this.onSubmit()
        } else {
          // Update categorie
          result = this.actionSubmit()
        }

        if (result) {
          this.$parent.$parent.$parent.$emit('onChangeTab', tab)
        }
      } catch (error) {
        // show error
        this.handleError(error.message)
      } finally {
        this.formProcessing = false
      }
    },
    /**
     * When the user do click on delete button
     *
     * @param {string} id - index from array
     */
    async handleDeleteButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          try {
            if (!isNil(this.currentId)) {
              // Loading
              this.formProcessing = true

              // Removing rations in dishes
              const { ok } = await deleteDishPricesByRationIdsAndPlaceId(
                [this.currentId],
                this.placeData.id
              )

              if (!ok) {
                throw new Error(
                  'Hubo un error al intentar eliminar los precios asociados a los platos.'
                )
              }

              // Removing ration in DB
              const { ok: okDeleteRation } = await deleteRationById(
                this.currentId,
                'place',
                this.placeData.id
              )

              if (!okDeleteRation) {
                throw new Error('Hubo un error al intentar eliminar la ración.')
              }

              // Actualizamos las raciones en el STORE
              this.getEveryRations({
                placeId: this.placeData.id,
                brand: getEnvironmentVariable('VUE_APP_BRAND')
              })

              // Evento general para que cualquier lista
              // a la escucha, recargue su listado
              this.$root.$emit('reload-list')

              // Close modal
              this.hideDialog()
            }
          } catch (error) {
            // show error
            this.handleError(error.message)
          } finally {
            this.formProcessing = false
          }
        },
        actionButtonText: 'Borrar',
        text: '¿Desea borrar el tipo de ración?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Inicia los datos del formulario
     */
    async getEveryNeededData() {
      try {
        if (!isNil(this.currentId)) {
          // Obtenemos datos
          this.rationData = await getRationById(this.currentId)
          // Para que los datos estén disponibles para el resto de pestañas (VuetifyTabs)
          this.changeItemsData({
            data: this.rationData
          })
          // Establecemos datos del formulario
          this.setFormFieldsValues(this.rationData)
        }
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Establecemos los datos del formulario que tratamos
     *
     * @param {object} data - objeto de datos a establecer
     */
    setFormFieldsValues(data) {
      this.formFields = {
        name: data.name,
        price: data.price
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Configuraciones del establecimiento
      const placeConfig = get(this.placeAddonsSetupByUser, ADDONS.place, {})
      // Datos formulario
      const rationDataOptions = {
        additionalLanguages: placeConfig.additionalLanguages,
        defaultLanguage: placeConfig.defaultLanguage
      }

      // Actualización
      if (!isNil(this.rationData)) {
        await updateRationById(
          {
            ...this.rationData,
            name: this.formFields.name,
            price: stringToNumber(this.formFields.price)
          },
          rationDataOptions
        )
      } else {
        // Creación
        const { ration } = await createRationInPlace(
          { name: this.formFields.name, price: stringToNumber(this.formFields.price), order: 0 },
          this.placeData.id,
          rationDataOptions
        )

        this.currentId = ration.id
        this.rationData = ration
      }

      // Almacenamos cambios "itemsData" (VuetifyTabs)
      // Para que los datos estén disponibles para el resto de pestañas (VuetifyTabs)
      this.changeItemsData({
        data: this.rationData
      })

      // Información resultados acción de salvado
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })

      // Actualizamos las raciones en el STORE
      this.getEveryRations({
        placeId: this.placeData.id,
        brand: getEnvironmentVariable('VUE_APP_BRAND')
      })

      // Evento general para que cualquier lista
      // a la escucha, recargue su listado
      this.$root.$emit('reload-list')
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      name: {
        required
      },
      price: {
        numberFormat: (value) => {
          return value ? isDecimalNumber(value) : true
        }
      }
    }
  }
}
